import { Tracking as BaseTracking } from '@folklore/tracking';
import { a } from '@react-spring/web';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import isArray from 'lodash/isArray';
import { v4 as uuidv4 } from 'uuid';

import TriggersManager from './TriggersManager';

class Tracking extends BaseTracking {
    constructor(opts = {}) {
        super(opts);

        this.user = null;
        this.identified = false;

        this.triggers = new TriggersManager(this.options);

        const { user = null } = this.options;
        if (user !== null) {
            this.setUser(user);
        }
    }

    pushNow(...args) {
        if (this.disabled) {
            return;
        }
        const push = () => {
            window.dataLayer.push(...args);

            if (typeof this.triggers !== 'undefined') {
                this.triggers.push(...args);
            }
        };
        if ('requestIdleCallback' in window) {
            requestIdleCallback(() => push());
        } else {
            push();
        }
    }

    setUser(user) {
        if (user === this.user && this.identified) {
            return;
        }

        this.user = user;
        this.identified = true;

        this.pushNow({
            event: 'identify',
            eventId: uuidv4(),
            identified: true,
            userId: user !== null ? user.id : null,
            user_id: user !== null ? user.id : null,
            user,
        });
    }

    trackPageViewFromDocument(document, extraData) {
        const {
            type,
            title,
            metadata: {
                trackingSettings = null,
                brands = [],
                categories = [],
                topics = [],
                formats = [],
                tags = null,
                credits = null,
                sponsors = null,
                publishedAt = null,
            } = {},
            micromag = null,
            identifiers,
            identifier,
        } = document;
        const { data: trackingData = null } = trackingSettings || {};
        const [brand = null] = brands || [];

        const publishedDate =
            publishedAt !== null ? format(parseISO(publishedAt), 'yyyy-MM-dd') : null;

        this.trackPageView({
            pageTitle: title,
            pageType: type,
            brand,
            brands,
            categories: [...(categories || []), ...(topics || [])],
            formats: [...(formats || [])],
            tags,
            publishedDate,
            authors: (credits || []).map(({ author = {} }) => (author || {}).slug || null),
            sponsors,
            micromag,
            identifiers,
            identifier,
            document,
            ...trackingData,
            ...extraData,
        });
    }

    trackPageView({
        brand = null,
        brands = null,
        section = null,
        categories = null,
        sponsors = null,
        tags = null,
        formats = null,
        ...data
    } = {}) {
        this.push({
            event: 'pageView',
            eventId: uuidv4(),

            hasPage: true,
            pageTitle: document.title,
            pageType: null,
            wordpressId: null,
            section: section !== null ? section.handle || section.slug || null : null,
            brand: brand !== null ? brand.handle : null,
            brands:
                brands !== null || brand !== null
                    ? [...(brands || []), brand]
                          .filter((it) => it !== null)
                          .map(({ handle }) => handle)
                    : null,
            categories:
                categories !== null ? categories.map(({ slug, handle }) => handle || slug) : null,
            tags: tags !== null ? tags.map(({ slug, handle }) => handle || slug) : null,
            formats: formats !== null ? formats.map(({ slug, handle }) => handle || slug) : null,
            publishedDate: null,
            authors: null,
            sponsors:
                sponsors !== null
                    ? sponsors.map(({ organisation = {} }) => (organisation || {}).handle || null)
                    : null,
            identifier: null,
            identifiers: null,
            document: null,
            documentProgress: 0,
            scrollPercent: 0,
            screenIndex: null,

            ...data,
        });
    }

    loadImagePixel(url) {
        this.push({
            event: 'loadImagePixel',
            eventId: uuidv4(),
            pixelImageUrl: url,
        });
    }

    trackEvent(category, action, label = null, value = null, data = {}) {
        this.push({
            event: 'eventInteraction',
            eventId: uuidv4(),
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: value,
            ...data,
        });
    }

    trackPromotion(promotion, action, data) {
        const { id = null, handle = null } = promotion;
        if (id === 'consent') {
            return;
        }
        this.trackEvent('Promotion', action, handle || id, null, {
            ...data,
            promotion,
        });
    }

    trackModal(id, action, data) {
        this.trackEvent('Modal', action, id, null, data);
    }

    trackNotification(id, action, data) {
        this.trackEvent('Notification', action, id, null, data);
    }

    trackSearch(query, data = {}) {
        this.trackEvent('Search', 'query', query, null, data);
    }

    trackSubscribe(subscription, source, data = {}) {
        this.trackEvent('Subscription', 'subscribe', subscription, null, {
            subscription_source: source,
            ...data,
        });
    }

    trackUnsubscribe(subscription, source, data = {}) {
        this.trackEvent('Subscription', 'unsubscribe', subscription, null, {
            subscription_source: source,
            ...data,
        });
    }

    trackVideo(
        action,
        {
            platform = null,
            id = null,
            url,
            title = null,
            duration = null,
            currentTime = null,
            thumbnail = null,
        } = {},
    ) {
        this.push({
            event: 'eventInteraction',
            eventId: uuidv4(),
            eventCategory: 'Video',
            eventAction: action,
            eventLabel: `${platform !== null ? `${platform}: ` : ''}${title || document.title}${
                id !== null ? ` (${id})` : ''
            }`,
            videoPlatform: platform,
            videoId: id,
            videoUrl: url,
            videoTitle: title,
            videoDuration: duration,
            videoCurrentTime: currentTime !== null ? Math.round(currentTime) : null,
            videoProgress:
                currentTime !== null && duration !== null && duration > 0
                    ? Math.round((currentTime / duration) * 100)
                    : null,
            videoThumbnail: thumbnail,
        });
    }

    trackAd(action, slot, { size = null } = {}) {
        const unitPath = slot.getAdPath();
        const targeting = slot.getTargeting();
        const adSize = size !== null && isArray(size) ? size.join('x') : size;
        this.push({
            event: 'eventInteraction',
            eventId: uuidv4(),
            eventCategory: 'Ads',
            eventAction: action,
            eventLabel: `${unitPath}${adSize !== null ? ` ${adSize}` : ''}`,
            adUnitPath: unitPath,
            adSize,
            adPosition: targeting !== null ? targeting.position || null : null,
        });
    }

    trackLink(link, data) {
        this.push({
            event: 'eventInteraction',
            eventId: uuidv4(),
            eventCategory: 'Navigation',
            eventAction: 'click_external',
            eventLabel: link,
            ...data,
        });
    }
}

export default Tracking;
