/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
// import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import { useDocumentSurtitle } from '../../hooks/useSurtitle';
import { useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';
import * as AppPropTypes from '../../lib/PropTypes';

import UrbaniaHeader from '../headers/UrbaniaHeader';
import Author from '../partials/Author';
import Category from '../partials/Category';
import Image from '../partials/Image';
import PlaceholderText from '../partials/PlaceholderText';
import Sponsor from '../partials/Sponsor';
import Surtitle from '../partials/Surtitle';
import Time from '../partials/Time';
import CardDescription from '../typography/CardDescription';
import DocumentMetadata from '../typography/DocumentMetadata';
import DocumentTitle from '../typography/DocumentTitle';

import styles from '../../styles/cards/document-featured-card.module.css';

const propTypes = {
    type: PropTypes.string,
    slug: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    linkQuery: PropTypes.object,
    sponsors: AppPropTypes.sponsors,
    surtitle: PropTypes.string,
    title: PropTypes.string,
    authorPrefix: PropTypes.string,
    date: PropTypes.string,
    topic: AppPropTypes.taxonomy,
    format: AppPropTypes.taxonomy,
    collection: AppPropTypes.collection,
    season: PropTypes.string,
    episode: PropTypes.string,
    micromagNumber: PropTypes.string,
    description: PropTypes.string,
    image: AppPropTypes.image,
    coverImage: AppPropTypes.image,
    categories: AppPropTypes.taxonomies,
    credits: AppPropTypes.credits,
    theme: PropTypes.oneOf([
        'over',
        'over-center',
        'over-top-left',
        'over-top-right',
        'over-bottom-left',
        'over-bottom-right',
        'over-right',
        'over-left',
        'left',
        'left-center',
        'right',
        'right-center',
        'vertical',
    ]),
    placeholder: PropTypes.bool,
    size: AppPropTypes.cardSize,
    ratio: AppPropTypes.cardRatio,
    responsive: PropTypes.bool,
    imageLoading: AppPropTypes.loading,
    label: PropTypes.node,
    imageSize: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    pictureStyle: PropTypes.object,
    withBorderRadius: PropTypes.bool,
    withoutText: PropTypes.bool,
    withCategory: PropTypes.bool,
    withoutSurtitle: PropTypes.bool,
    withoutAuthor: PropTypes.bool,
    withoutAuthorBox: PropTypes.bool,
    withoutAuthorImage: PropTypes.bool,
    withoutDate: PropTypes.bool,
    withoutDescription: PropTypes.bool,
    withoutSponsor: PropTypes.bool,
    withBreakpoints: PropTypes.bool,
    withUrbaniaLogo: PropTypes.bool,
    withMicromagSummary: PropTypes.bool,
    withoutLink: PropTypes.bool,
    withImageLink: PropTypes.bool,
    innerClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    imageContainerClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    type: null,
    slug: null,
    linkQuery: null,
    title: null,
    surtitle: null,
    description: null,
    authorPrefix: null,
    image: null,
    coverImage: null,
    date: null,
    topic: null,
    format: null,
    collection: null,
    season: null,
    episode: null,
    micromagNumber: null,
    categories: null,
    credits: null,
    sponsors: null,
    theme: 'null',
    placeholder: false,
    size: null,
    ratio: 'horizontal',
    responsive: false,
    imageLoading: 'lazy',
    label: null,
    imageSize: null,
    pictureStyle: null,
    withBorderRadius: false,
    withoutText: false,
    withCategory: false,
    withoutSurtitle: false,
    withoutDate: false,
    withoutDescription: false,
    withoutAuthor: false,
    withoutAuthorBox: false,
    withoutAuthorImage: false,
    withoutSponsor: false,
    withBreakpoints: false,
    withUrbaniaLogo: false,
    withMicromagSummary: false,
    withoutLink: false,
    withImageLink: false,
    innerClassName: null,
    labelClassName: null,
    titleClassName: null,
    imageContainerClassName: null,
    imageClassName: null,
    className: null,
};

function DocumentFeaturedCard({
    slug,
    linkQuery,
    type,
    image,
    coverImage,
    categories,
    credits,
    sponsors,
    title,
    surtitle,
    date,
    topic,
    format,
    collection,
    season,
    episode,
    micromagNumber,
    description,
    authorPrefix,
    theme,
    size,
    ratio,
    responsive,
    placeholder,
    imageLoading,
    label,
    imageSize,
    pictureStyle,
    withBorderRadius,
    withoutText,
    withCategory,
    withoutSurtitle,
    withoutDate,
    withoutDescription,
    withoutAuthor,
    withoutAuthorBox,
    withoutAuthorImage,
    withoutSponsor,
    withBreakpoints,
    withUrbaniaLogo,
    withMicromagSummary,
    withoutLink,
    withImageLink,
    innerClassName,
    labelClassName,
    titleClassName,
    imageContainerClassName,
    imageClassName,
    className,
}) {
    const url = useDocumentUrlGenerator();
    const [category = null] = categories || [];
    const [{ author = null } = {}] = credits || [];
    const [sponsor = null] = sponsors || [];

    const isOver = theme !== null && theme.indexOf('over') === 0;
    const isBox = theme !== null && theme.indexOf('box') === 0;
    const isOutside = !isOver;

    const finalSurtitle = useDocumentSurtitle(
        {
            surtitle,
            collection,
            topic,
            format,
            season,
            episode,
            categories,
            micromagNumber,
        },
        {
            withCategory: !withCategory,
        },
    );

    // const finalRatio = ratio !== null ? getRatio(ratio) : null;

    const CardComponent = slug !== null && !withoutLink ? Link : 'div';

    const imageElement = (
        <Image
            className={classNames([
                styles.image,
                {
                    [imageClassName]: imageClassName !== null,
                },
            ])}
            pictureClassName={styles.picture}
            media={isOutside ? coverImage || image : image}
            size={imageSize || 'responsive'}
            loading={imageLoading}
            withPlaceholder={placeholder}
            pictureStyle={pictureStyle}
        />
    );

    const meta = [
        (author !== null || placeholder) && !withoutAuthor ? (
            <Author
                key="meta-author"
                {...author}
                prefix={authorPrefix}
                imagePosition={theme === 'right' ? 'right' : 'left'}
                placeholder={placeholder}
                withBox={!withoutAuthorBox}
                withoutImage={withoutAuthorImage}
                className={classNames([
                    styles.author,
                    {
                        [styles.withBox]: !withoutAuthorBox,
                    },
                ])}
            />
        ) : null,
        date !== null && !withoutDate ? (
            <DocumentMetadata className={styles.date} key="meta-date">
                <Time date={date} />
            </DocumentMetadata>
        ) : null,
    ].filter((it) => it !== null);

    return (
        <CardComponent
            href={
                !withoutLink
                    ? url(
                          {
                              type,
                              slug,
                          },
                          linkQuery,
                      )
                    : '#'
            }
            className={classNames([
                styles.container,
                {
                    [styles.responsive]: responsive,
                    [styles.isOver]: isOver,
                    [styles.isOutside]: isOutside,
                    [styles.isBox]: isBox,
                    [styles.withRatio]: ratio !== null,
                    [styles.withoutText]: withoutText && !withMicromagSummary,
                    [styles.withBorderRadius]: withBorderRadius,
                    [styles.withBreakpoints]: withBreakpoints,
                },
                styles[camelCase(ratio || 'none')],
                styles[camelCase(size)],
                styles[camelCase((theme || '').replace(/^(over|box)-/, ''))],
                styles[camelCase(theme)],
                className,
            ])}
        >
            <span className={classNames([styles.inner, innerClassName])}>
                {label !== null ? (
                    <div className={classNames([styles.label, labelClassName])}>{label}</div>
                ) : (
                    <span className={classNames([styles.label, labelClassName])}>
                        <span className={styles.before}>
                            {(category !== null || placeholder) && withCategory ? (
                                <Category
                                    {...category}
                                    placeholder={placeholder}
                                    className={styles.category}
                                />
                            ) : null}
                            {finalSurtitle !== null && !withoutSurtitle ? (
                                <Surtitle surtitle={finalSurtitle} className={styles.surtitle} />
                            ) : null}
                        </span>
                        <DocumentTitle
                            className={classNames([
                                styles.title,
                                {
                                    [titleClassName]: titleClassName !== null,
                                },
                            ])}
                        >
                            {placeholder ? (
                                <PlaceholderText minCharacters={4} maxCharacters={6} lines={3} />
                            ) : (
                                title
                            )}
                        </DocumentTitle>
                        <span className={styles.after}>
                            {description !== null && !withoutDescription ? (
                                <CardDescription
                                    className={styles.description}
                                    html={description}
                                />
                            ) : null}
                            {meta.length > 0 ? <div className={styles.meta}>{meta}</div> : null}
                            {sponsor !== null && !withoutSponsor ? (
                                <Sponsor
                                    {...sponsor}
                                    iconOnly
                                    className={styles.sponsor}
                                    labelClassName={styles.sponsorLabel}
                                />
                            ) : null}
                        </span>
                    </span>
                )}
                <span className={classNames([styles.imageContainer, imageContainerClassName])}>
                    {withImageLink ? (
                        <Link
                            href={
                                url(
                                    {
                                        type,
                                        slug,
                                    },
                                    linkQuery,
                                ) || '#'
                            }
                        >
                            {imageElement}
                        </Link>
                    ) : (
                        imageElement
                    )}
                    {withUrbaniaLogo ? <UrbaniaHeader className={styles.urbaniaLogo} /> : null}
                </span>
            </span>
        </CardComponent>
    );
}

DocumentFeaturedCard.propTypes = propTypes;
DocumentFeaturedCard.defaultProps = defaultProps;

export default DocumentFeaturedCard;
