/* eslint-disable react/jsx-props-no-spreading */
import '../styles/styles.css';

import { useRoutes } from '@folklore/routes';
import BlocksProvider from '@niche-js/blocks';
import { BLOCKS_NAMESPACE, ComponentsProvider } from '@niche-js/core/contexts';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useRoute, useSearch } from 'wouter';

import { AdsProvider } from '../contexts/AdsContext';
import { UserAdsTargetingProvider } from '../contexts/AdsTargeting';
import { AuthContextProvider } from '../contexts/AuthContext';
import { ConsentContextProvider } from '../contexts/ConsentContext';
import { DeviceContextProvider } from '../contexts/DeviceContext';
import { FacebookContextProvider } from '../contexts/FacebookContext';
import { GoogleContextProvider } from '../contexts/GoogleContext';
import { PopupsProvider } from '../contexts/PopupsContext';
import { PromotionsProvider } from '../contexts/PromotionsContext';
import { useSite } from '../contexts/SiteContext';
import { TrackingProvider } from '../contexts/TrackingContext';
// import { LayoutContextProvider } from '../contexts/LayoutContext';
import { VisitorContextProvider } from '../contexts/VisitorContext';
import slots from '../data/adsSlots';
import * as richAdComponents from './ads/index';
import * as BlockComponents from './blocks/index';
import ReaderLayout from './layouts/ReaderLayout';
import TimelineLayout from './layouts/TimelineLayout';
import RedirectorPage from './pages/RedirectorPage';

const ShareRoutes = React.lazy(() => import('./ShareRoutes'));

const propTypes = {
    device: PropTypes.shape({}),
    isRedirector: PropTypes.bool,
    presentation: PropTypes.oneOf([null, 'reader', 'capture']),
    tagManagerId: PropTypes.string,
    adSlotsPath: PropTypes.objectOf(PropTypes.string),
    bncDCId: PropTypes.string,
    facebookAppId: PropTypes.string,
    googleClientId: PropTypes.string,
};

const defaultProps = {
    device: null,
    isRedirector: false,
    presentation: null,
    tagManagerId: 'GTM-WBRBHVQ',
    adSlotsPath: {
        default: '/62442913/URBANIA',
        bumper: '/62442913/timeline',
    },
    bncDCId: 'DC-9722614',
    facebookAppId: '1051760225433251',
    googleClientId: '432379609774-r4t5037c96fnp2ja3te6dq9i74dke3uq.apps.googleusercontent.com',
};

function App({
    device,
    isRedirector,
    adSlotsPath: defaultSlotsPath,
    presentation,
    tagManagerId,
    bncDCId,
    facebookAppId,
    googleClientId,
}) {
    const routes = useRoutes();
    const { handle: siteHandle = null, adSlotsPath = defaultSlotsPath } = useSite();

    const [isReader, setIsReader] = useState(
        presentation === 'reader' || presentation === 'capture',
    );
    const onQuitReader = useCallback(() => setIsReader(false), []);

    useEffect(() => {
        if (isRedirector) {
            return;
        }

        const firstScript = document.getElementsByTagName('script')[0];

        // GTM
        const gtm = document.createElement('script');
        gtm.async = true;
        gtm.src = `https://www.googletagmanager.com/gtm.js?id=${tagManagerId}`;
        firstScript.parentNode.insertBefore(gtm, firstScript);

        // GPT
        const gpt = document.createElement('script');
        gpt.async = true;
        gpt.crossorigin = 'anonymous';
        gpt.src = `https://securepubads.g.doubleclick.net/tag/js/gpt.js`;
        firstScript.parentNode.insertBefore(gpt, firstScript);

        // BNC
        const bnc = document.createElement('script');
        bnc.async = true;
        bnc.src = `https://www.googletagmanager.com/gtag/js?id=${bncDCId}&l=bncDataLayer`;
        firstScript.parentNode.insertBefore(bnc, firstScript);
    }, [tagManagerId, bncDCId]);

    const trackingVariables = useMemo(
        () => ({
            presentationMode: presentation,
            presentation,
        }),
        [presentation],
    );

    let page = null;
    if (isRedirector) {
        page = <RedirectorPage />;
    } else if (isReader) {
        page = <ReaderLayout onQuit={onQuitReader} presentation={presentation} />;
    } else {
        page = <TimelineLayout />;
    }

    return (
        <BlocksProvider>
            <ComponentsProvider namespace={BLOCKS_NAMESPACE} components={BlockComponents}>
                <AuthContextProvider disabled={isRedirector}>
                    <ConsentContextProvider>
                        <VisitorContextProvider disabled={isRedirector}>
                            <TrackingProvider disabled={isRedirector} variables={trackingVariables}>
                                <FacebookContextProvider
                                    appId={facebookAppId}
                                    locale={siteHandle === 'france' ? 'fr_FR' : 'fr_CA'}
                                >
                                    <GoogleContextProvider clientId={googleClientId}>
                                        <PromotionsProvider>
                                            <AdsProvider
                                                slotsPath={adSlotsPath}
                                                slots={slots}
                                                disableTracking
                                                disabled={isRedirector}
                                                fetchMarginPercent={100}
                                                renderMarginPercent={100}
                                                richAdComponents={richAdComponents}
                                                // disableLazyLoad
                                                disableSingleRequest
                                                mobileScaling={1.5}
                                            >
                                                <DeviceContextProvider {...device}>
                                                    <UserAdsTargetingProvider>
                                                        <PopupsProvider>
                                                            <Switch>
                                                                <Route
                                                                    path={
                                                                        routes[
                                                                            'document.share.wildcard'
                                                                        ]
                                                                    }
                                                                >
                                                                    <Suspense>
                                                                        <ShareRoutes />
                                                                    </Suspense>
                                                                </Route>
                                                                <Route>{page}</Route>
                                                            </Switch>
                                                        </PopupsProvider>
                                                    </UserAdsTargetingProvider>
                                                </DeviceContextProvider>
                                            </AdsProvider>
                                        </PromotionsProvider>
                                    </GoogleContextProvider>
                                </FacebookContextProvider>
                            </TrackingProvider>
                        </VisitorContextProvider>
                    </ConsentContextProvider>
                </AuthContextProvider>
            </ComponentsProvider>
        </BlocksProvider>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
