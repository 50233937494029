import isArray from 'lodash/isArray';
import { pascalCase } from 'pascal-case';

export function formatDuration(seconds) {
    const parts = [];
    const hours = Math.floor(seconds / (60 * 60));
    if (hours > 0) {
        parts.push(hours);
    }
    const minutes = Math.floor((seconds - hours * (60 * 60)) / 60);
    parts.push(hours > 0 && minutes < 10 ? `0${minutes}` : minutes);
    const remainingSeconds = Math.round(seconds - hours * (60 * 60) - minutes * 60);
    parts.push(remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds);
    return parts.join(':');
}

export function formatDurationIso8601(seconds) {
    const units = ['S', 'M', 'H'];
    return `PT${formatDuration(seconds)
        .split(':')
        .reverse()
        .map((it, index) => `${it}${units[index]}`)
        .reverse()
        .join('')}`;
}

export function getPrefixedProps(props, prefix) {
    return ((props !== null && !isArray(props) ? [props] : props) || []).reduce(
        (map, it) => ({
            ...map,
            [`${prefix}${pascalCase(it)}`]: true,
        }),
        null,
    );
}

export function getTrackingQuery({ recommendationId = null, clickRef = null } = {}) {
    if (recommendationId === null && clickRef === null) {
        return null;
    }
    return {
        ...(recommendationId !== null ? { _rid: recommendationId } : null),
        ...(clickRef !== null ? { _ref: clickRef } : null),
    };
}
