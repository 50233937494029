/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
import { useTracking } from '@folklore/tracking';
import { animated, useSpring } from '@react-spring/web';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock-upgrade';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag } from 'react-use-gesture';

import getDisplayName from '../../lib/getDisplayName';

import { NOTIFICATION_NAMESPACE } from '../../contexts/PopupsContext';
import CloseButton from '../buttons/CloseButton';
import PopupPortal from './PopupPortal';

import styles from '../../styles/popups/notification.module.css';

const propTypes = {
    id: PropTypes.string,
    opened: PropTypes.bool,
    position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
    withTransition: PropTypes.bool,
    withScrollLock: PropTypes.bool,
    withoutCloseButton: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    requestClose: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
};

const defaultProps = {
    id: null,
    opened: true,
    position: 'bottom-right',
    withTransition: false,
    withScrollLock: false,
    withoutCloseButton: false,
    children: null,
    requestClose: null,
    className: null,
    onOpened: null,
    onClosed: null,
};

const Notification = ({
    id,
    opened,
    position,
    withTransition,
    withScrollLock,
    withoutCloseButton,
    className,
    children,
    requestClose,
    onOpened,
    onClosed,
}) => {
    const name = getDisplayName(children);
    const tracking = useTracking();
    const finalId = useMemo(() => id || name || 'Notification', [id, name]);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [transitionAxis, setTransitionAxis] = useState('x');
    const containerRef = useRef();
    const spring = useSpring({
        [transitionAxis === 'y' ? 'x' : 'y']: '0%',
        [transitionAxis]: opened ? '0%' : '100%',
        // opacity: opened ? 1 : 0,
        onStart: () => {
            setIsTransitioning(true);
        },
        onResolve: () => {
            setIsTransitioning(false);
            if (opened && withScrollLock) {
                disableBodyScroll(containerRef.current);
            } else if (!opened && withScrollLock) {
                clearAllBodyScrollLocks();
            }
            if (opened && onOpened !== null) {
                onOpened();
            } else if (!opened && onClosed !== null) {
                onClosed();
            }
        },
    });

    useEffect(() => {
        if (!withTransition) {
            return;
        }
        if (opened && withScrollLock) {
            disableBodyScroll(containerRef.current);
        } else if (!opened && withScrollLock) {
            clearAllBodyScrollLocks();
        }
        if (opened && onOpened !== null) {
            onOpened();
        } else if (!opened && onClosed !== null) {
            onClosed();
        }
    }, [opened, onOpened, onClosed, withTransition]);

    useEffect(
        () => () => {
            if (withScrollLock) {
                clearAllBodyScrollLocks();
            }
        },
        [withScrollLock],
    );

    const bindSwipe = useDrag(
        ({ swipe: [swipeX, swipeY] }) => {
            setTransitionAxis(Math.abs(swipeX) > Math.abs(swipeY) ? 'x' : 'y');
            if (swipeX === 1 || swipeY === 1) {
                requestClose();
            }
        },
        {
            enabled: opened,
            preventScroll: true,
            // axis: 'xy',
        },
    );

    const onClickClose = useCallback(() => {
        requestClose();
    }, [requestClose]);

    useEffect(() => {
        tracking.trackNotification(id, opened ? 'open' : 'close');
    }, [id, opened]);

    return (
        <PopupPortal id={finalId} requestClose={requestClose} namespace={NOTIFICATION_NAMESPACE}>
            <animated.div
                style={withTransition ? spring : null}
                className={classNames([
                    styles.container,
                    {
                        [styles.isTransitioning]: withTransition && isTransitioning,
                    },
                    styles[camelCase(position)],
                    className,
                ])}
                {...bindSwipe()}
                ref={containerRef}
            >
                {children}
                {!withoutCloseButton ? (
                    <CloseButton onClick={onClickClose} className={styles.closeButton} />
                ) : null}
            </animated.div>
        </PopupPortal>
    );
};

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
